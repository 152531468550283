import { Reducer } from 'redux';
import LookedupUserState from '../states/lookedupUserState';
import UserProfile from '../../common/types/UserProfile';
import ProfilePropertyFilters from '../../common/types/ProfilePropertyFilters';
import {
  ProfileActionTypes,
  GetUserProfileByUsernameActionType,
  GetUserProfileByUsernameCompletedActionType,
  GetUserProfileByUsernameFailedActionType,
  UpdateLookedUpFiltersActionType,
  UpdateLookedUpNFTFiltersActionType,
  UpdateLookedUpViewActionType,
  UpdateLookedUpSparkHistoryFiltersActionType
} from '../actions/profileActions';
import ProfileNFTFilters from '../../common/types/ProfileNFTFilters';

export type ProfileActions = 
  GetUserProfileByUsernameActionType
  | GetUserProfileByUsernameCompletedActionType
  | GetUserProfileByUsernameFailedActionType
  | UpdateLookedUpFiltersActionType
  | UpdateLookedUpNFTFiltersActionType
  | UpdateLookedUpViewActionType
  | UpdateLookedUpSparkHistoryFiltersActionType;

const initialUserProfile: UserProfile = {
  username: '',
  eosAccount: '',
  avatarLink: '',
  avatarColor: '',
  rank: '',
  networth: 0,
  jailed: false,
  uplandInMaintenance: false,
  registeredUser: false,
  supporter: false,
  runCount: 0,
  maxRuns: 0,
  upxToNextRun: 0,
  upxToSupporter: 0,
  registeredUserId: 0,
  collections: [],
  badges: [],
  properties: [],
  profileNFTs: [],
  homeAddress: '',
  homePropertyId: 0,
  homePropertyImage: '',
  joined: new Date(),
  unstakedSpark: 0,
  stakedSpark: 0,
  monthlyEarnings: 0,
  sparkHistory: [],
  liquidUPX: 0,
}

const initialFilterState: ProfilePropertyFilters = {
  Address: '',
  City: '',
  Neighborhood: '',
  Status: '',
  Building: '',
  AddressSort: 0,
  CitySort: 0,
  NeighborhoodSort: 0,
  SizeSort: 0,
  CollectionSort: 0,
  MintSort: 0,
  BoostSort: 0,
  StatusSort: 0,
  BuildingSort: 0,
  MintedSort: 0,
  AcquiredOnSort: 0,
  PageNumber: 1
}

const initialNFTFilterState: ProfileNFTFilters = {
  name: '',
  team: '',
  season: '',
  position: '',
  model: '',
  opponent: '',
  homeTeam: '',
  series: '',
  description: '',
  rarity: '',
  buildingType: '',
  address: '',
  manufacturer: '',
  legitType: '',

  mintSort: 0,
  nameSort: 0,
  teamSort: 0,
  seasonSort: 0,
  positionSort: 0,
  fanPointsSort: 0,
  modelSort: 0,
  gameDateSort: 0,
  opponentSort: 0,
  homeTeamSort: 0,
  variantSort: 0,
  seriesSort: 0,
  raritySort: 0,
  buildingTypeSort: 0,
  addressSort: 0,
  descriptionSort: 0,
  manufacturerSort: 0,
  legitTypeSort: 0,

  pageNumber: 1
}

const initialSparkHistoryFilters = {
  name: '',
  address: '',

  nameSort: 0,
  addressSort: 0,
  amountSort: 0,
  startSort: 0,
  endSort: 0,
  sparkHoursSort: 0,
  manufacturingSort: 0,
  
  pageNumber: 1
}

const initialState: LookedupUserState = {
  userProfile: initialUserProfile,
  isLoading: false,
  hasError: false,
  errorMessage: '',
  filters: initialFilterState,
  nftFilters: initialNFTFilterState,
  sparkHistoryFilters: initialSparkHistoryFilters,
  view: 'Properties',
  viewCategory: 'blkexplorer'
}

export const LookedupUserReducer: Reducer<ProfileState> = (state = initialState, action: ProfileActions): ProfileState => {
  switch(action.type) {
    case ProfileActionTypes.GetUserProfileByUsername:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: '',
        userProfile: initialUserProfile,
        filters: initialFilterState
      };
    case ProfileActionTypes.GetUserProfileByUsernameCompleted:
      return {
        ...state,
        userProfile: action.payload!.response.userProfile,
        isLoading: false,
        hasError: false,
        errorMessage: '',
        filters: {
          ...state.filters,
          pageNumber: 1
        } 
      };
    case ProfileActionTypes.GetUserProfileByUsernameFailed:
      return {
        ...state,
        userProfile: initialUserProfile,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload!.error as any,
        filters: {
          ...state.filters,
          pageNumber: 1
        } 
      };
    case ProfileActionTypes.UpdateLookedUpFilters:
      return {
        ...state,
        filters: action.payload!.filters
      }
    case ProfileActionTypes.UpdateLookedUpNFTFilters:
      return {
        ...state,
        nftFilters: action.payload!.filters
      }
    case ProfileActionTypes.UpdateLookedUpView:
      return {
        ...state,
        view: action.payload!.view,
        viewCategory: action.payload!.category
      }
    case ProfileActionTypes.UpdateLookedUpSparkHistoryFilters:
      return {
        ...state,
        sparkHistoryFilters: action.payload!.filters
      }
    default:
      return state;
  }
}

export default LookedupUserReducer;