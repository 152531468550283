import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Form, Button, Col } from 'react-bootstrap'
import UserProfile from '../../common/types/UserProfile';
import { PropertyTable } from '../PropertyTableComponent/PropertyTable';
import ProfilePropertyFilters from '../../common/types/ProfilePropertyFilters';
import './UserProfile.css';
import WebCollection from '../../common/types/WebCollection';
import { faDownload, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NFTTableComponent } from '../NFTTableComponent/NFTTabelComponent';
import { nftCategoriesEnum } from '../../common/enums/nftCategoriesEnum';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import WebNFTHistory from '../../common/types/WebNFTHistory';
import { profileViewOptions } from '../../common/enums/profileViewOptionsEnum';
import ProfileNFTFilters from '../../common/types/ProfileNFTFilters';
import { SparkStakingHistoryComponent } from '../SparkStakingHistoryComponent/SparkStakingHistoryComponent';
import ProfileSparkHistoryFilters from '../../common/types/ProfileSparkHistoryFilters';

interface OwnProps {
  userProfile: UserProfile,
  isLookup: boolean,
  hasError: boolean,
  filters: ProfilePropertyFilters,
  getUserByUsername: Function,
  updateFilters: Function,
  isLoading: boolean,
  collections: WebCollection[],
  getNFTHistory: Function,
  isLoadingHistory: boolean,
  nftHistory: WebNFTHistory[],
  hasLoadingHistoryError: boolean,
  closeNFTHistory: Function,
  historyDGoodId: number,
  getPropertyHistory: Function,
  updateSparkHistoryFilters: Function

  sparkHistoryFilters: ProfileSparkHistoryFilters,
  nftFilters: ProfileNFTFilters,
  updateView: Function,
  updateNFTFilters: Function,
  view: string,
  viewCategory: string,
  toggleImageModal: Function
};

export type UserProfileProps = OwnProps;

export const UserProfileComponent = (props: UserProfileProps) => {

  const [svg, setSvg] = useState('');
  const [loading, setLoading] = useState(false);
  const [uplandUsername, setUplandUsername] = useState('');

  useEffect(() => {
    if (!svg && props.userProfile.avatarLink) {
      setLoading(true);
      fetch(props.userProfile.avatarLink)
        .then(res => res.text())
        .then(text => { setSvg(text); setLoading(false); });
    }
  }, [props.userProfile.avatarLink, svg])

  const renderAvatar = () => {
    if (loading) {
      return (
        <div className="spinner"/>
      );
    } else if(!svg) {
      return (
        <div className="error"/>
      );
    }
  
    return (
      <div style={{backgroundColor: `${props.userProfile.avatarColor}`}} className="avatar-circle" dangerouslySetInnerHTML={{ __html: svg}}/>
    );
  }

  const downloadCSV = () => {
    let filename = `${props.userProfile.username}_properties.csv`;
    let headerData = ["City,Address,Neighborhood,Size,Mint,Status,CollectionIds,Building,Minted,AcquiredOn"];

    let propData = props.userProfile.properties.map((p) => { 
      return p.city + "," + p.address.replace(',', '') + "," + p.neighborhood.replace(',', ' ') + "," + p.size + "," + p.mint + "," + p.status + "," + p.collectionIds.join(" ") + "," + p.building +  "," + p.minted  + "," + p.acquiredOn; 
    });
    let csvData = headerData.concat(propData).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 
  }

  const downloadCSVNFTs = () => {
    let filename = `${props.userProfile.username}_${props.viewCategory}.csv`;

    let headerString = "DGoodId,SerialNumber,CurrentSupply,MaxSupply,Name,Owner"
    switch (props.viewCategory) {
      case "blkexplorer":
        headerString += ",SeriesName,Rarity";
        break;
      case "essential":
        headerString += ",Team,Year,Position,FanPoints,ModelType,IsVariant,LegitType";
        break;
      case "memento":
        headerString += ",Team,Year,Position,FanPoints,ModelType,GameDate,Opponent,HomeTeam,LegitType"
        break;
      case "spirithlwn":
        headerString += ",Rarity"
        break;
      case "structornmt":
        headerString += ",BuildingType,Rarity"
        break;
      case "structure":
        break;
      case "pass":
        headerString += ",Year,FanPoints,ModelType,LegitType"
        break;
    }
    headerString += ",Link";

    let headerData = [headerString];

    let nftData = props.userProfile.profileNFTs.filter(n => n.category === props.viewCategory).map((n) => { 
      let nftString = "" + n.dGoodId + "," + n.serialNumber + "," + n.currentSupply + "," + n.maxSupply + "," + n.name.replace(",", " ") + "," + n.owner;
      switch (props.viewCategory) {
        case "blkexplorer":
          nftString += "," + n.seriesName + "," + n.rarity;
          break;
        case "essential":
          nftString += "," + n.team + "," + n.year + "," + n.position + "," + n.fanPoints + "," + n.modelType + "," + n.isVariant + "," + n.legitType;
          break;
        case "memento":
        nftString += "," + n.team + "," + n.year + "," + n.position + "," + n.fanPoints + "," + n.modelType + "," + n.gameDate + "," + n.opponent + "," + n.homeTeam + "," + n.legitType;
          break;
        case "spirithlwn":
          nftString += "," + n.rarity;
          break;
        case "structornmt":
          nftString += "," + n.buildingType + "," + n.rarity;
          break;
        case "structure":
          break;
          case "pass":
          nftString += "," + n.year + "," + n.fanPoints + "," + n.modelType + "," + n.legitType;
          break;
      }
      nftString += "," + n.link;

      return nftString;
    });
    let csvData = headerData.concat(nftData).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 
  }

  const downloadCSVSparkHistory = () => {
    let filename = `${props.userProfile.username}_sparkHistory.csv`;
    let headerData = ["Name,Address,Spark,Start,End,SparkHours"];

    let sparkData = props.userProfile.sparkHistory.map((p) => { 
      return p.name + "," + p.address.replace(',', '') + "," + p.amount + "," + p.start + "," + p.end + "," + p.sparkHours; 
    });
    let csvData = headerData.concat(sparkData).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 
  }

  const callLoadUser = () => {
    props.getUserByUsername(uplandUsername);
  }

  const updateUsernameInState = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {;
    setUplandUsername(changeEvent.currentTarget.value);
  } 

  const handleEnterKeyPress = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      callLoadUser();
    }
  }

  const renderCategorySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Category <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.viewCategory}
              onChange={e => {
                props.updateView(props.view, e.target.value);
              }}>
              {nftCategoriesEnum.map((c: KeyValuePair) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderViewOptionSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>View <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.view}
              onChange={e => {
                props.updateView(e.target.value, props.viewCategory);
              }}>
              {profileViewOptions.map((c: KeyValuePair) => <option key={c.key} value={c.value}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const performDownloadCSV = () => {
    if (props.view === "Properties") {
      downloadCSV();
    } else if (props.view === "NFTs") {
      downloadCSVNFTs();
    } else if (props.view === "Spark History") {
      downloadCSVSparkHistory();
    }
  }

  const renderRegisterdUserCol = () => {
    if (props.isLookup) {
      return (
        <Form>
          <Form.Group className="login-margin formGroup-padding">
            <Form.Label>Upland Username</Form.Label>
            <Form.Control type="text" value={uplandUsername} onChange={updateUsernameInState} onKeyPress={handleEnterKeyPress}/>
          </Form.Group>
          <Form.Group className="login-margin formGroup-padding">
            <Button variant="dark" onClick={callLoadUser}>Search</Button>
          </Form.Group>
          <Button className="profile-download-csv" variant="dark" onClick={performDownloadCSV} disabled={props.isLoading}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button> 
        </Form>
      );
    }

    if (!props.userProfile.registeredUser) {
      return (
        <div>
        </div>
      );
    }

    if (props.userProfile.supporter) {
      return (
        <div>
          <Row><h1>Supporter</h1></Row>
          <Row><h3>Thank You!</h3></Row>
          <Button className="profile-download-csv" variant="dark" onClick={performDownloadCSV} disabled={props.isLoading}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button>
        </div>
      );
    } else {
      return (
        <div>
          {props.userProfile.runCount >= props.userProfile.maxRuns ? 
            <Row><u><b><h5>No Runs Left, Vist Locations</h5></b></u></Row> : 
            <Row><h5>Runs Used: {props.userProfile.runCount} / {props.userProfile.maxRuns}</h5></Row>}
          <Row><h5>UPX to Next Run: {props.userProfile.upxToNextRun}</h5></Row>
          <Row><h5>UPX to Supporter: {props.userProfile.upxToSupporter}</h5></Row>
          <Button className="profile-download-csv" variant="dark" onClick={performDownloadCSV} disabled={props.isLoading}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button>
        </div>
      );
    }
  }

  const renderNFTTable = () => {
    return (
      <NFTTableComponent 
        nfts={props.userProfile.profileNFTs.filter(n => n.category === props.viewCategory)}
        category={props.viewCategory}
        getNFTHistory={props.getNFTHistory}
        isLoadingHistory={props.isLoadingHistory}
        closeNFTHistory={props.closeNFTHistory}
        hasLoadingHistoryError={props.hasLoadingHistoryError}
        nftHistory={props.nftHistory}
        historyDGoodId={props.historyDGoodId}
        filters={props.nftFilters}
        updateFilters={props.updateNFTFilters}
        toggleImageModal={props.toggleImageModal}
        />
      )
  }

  const renderInfoColumn = () => {
    if (!props.isLoading && !props.hasError && props.userProfile && props.userProfile.username && props.userProfile.username !== "" ) {
      return (
        <div className="profile-Username">
          <Row><h2>{props.userProfile.username}</h2></Row>
          <Row><h4>{props.userProfile.rank}</h4></Row>
          <Row><h6>{props.userProfile.eosAccount}</h6></Row>
          <Row><h6>Joined: {props.userProfile.joined}</h6></Row>
          {
            props.userProfile.homeAddress && props.userProfile.homeAddress !== "" ?
              <Row><h6>{props.userProfile.homeAddress}</h6></Row> : <></>
          }
        </div>
      );
    } if (props.hasError) {
      return (
        <div className="profile-Username">
          <Row><h2>Could Not Find User {props.userProfile.username}</h2></Row>
        </div>
      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }

  const getNFTCountString = () => {
    if (props.view === "Properties") {
      return "NFTs: " + props.userProfile.profileNFTs.length;
    } else if (props.view === "NFTs") {
      return nftCategoriesEnum.filter(n => n.key === props.viewCategory)[0].value + ": " + props.userProfile.profileNFTs.filter(n => n.category === props.viewCategory).length;
    } else {

    }
  }

  const renderDetailsColumn = () => {
    if (!props.hasError && props.userProfile && props.userProfile.username && props.userProfile.username !== "" ) {
      return (
        <div>
          {
            props.userProfile.uplandInMaintenance ? 
              <Row><h6>Upland In Maintenance</h6></Row>
              : 
              <Row><h6>Networth: {props.userProfile.networth.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} upx</h6></Row>
          }
          <Row><h6>Liquid UPX: {props.userProfile.liquidUPX.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} upx</h6></Row>
          <Row><h6>Monthly Earnings: {props.userProfile.monthlyEarnings.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} upx</h6></Row>
          <Row><h6>Properties: {props.userProfile.properties.length.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h6></Row>
          <Row><h6>Spark: Unstaked {props.userProfile.unstakedSpark.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} Staked {props.userProfile.stakedSpark.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h6></Row>
          <Row>
            <h6>
              { getNFTCountString() }
            </h6>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }

  const renderSelectedTable = () => {
    if (props.view === "Properties") {
      return <PropertyTable properties={props.userProfile.properties} filters={props.filters} updateFilters={props.updateFilters} collections={props.collections} getPropertyHistory={props.getPropertyHistory} />
    } else if (props.view === "NFTs") {
      return renderNFTTable();
    } else {
      return <SparkStakingHistoryComponent sparkHistory={props.userProfile.sparkHistory} filters={props.sparkHistoryFilters} updateFilters={props.updateSparkHistoryFilters}/>;
    }
  }

  const renderOptionColumn = () => {
    if (!props.hasError && props.userProfile && props.userProfile.username && props.userProfile.username !== "" ) {
      return (
        <div>
          <Row>
            {renderViewOptionSelect()}
          </Row>
          <Row>{props.view === "NFTs" && renderCategorySelect()}</Row>
        </div>
      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }

  return (
    <Container fluid={true} className="bg-secondary text-light">
      <Row className="profile-header">
        <div className="avatar-col">
          {renderAvatar()}
        </div>
        <div className="username-col">
          {renderInfoColumn()}
        </div>
        <div className="input-form-column username-col">
          {renderDetailsColumn()}
        </div>
        <div className="input-form-column header-info-column-width-300">
          {renderOptionColumn()}
        </div>
        <div className="registered-user-col">
          {renderRegisterdUserCol()}
        </div>
      </Row>
      <Row>
        { renderSelectedTable() }
      </Row>
    </Container>
  );
}

export default connect(null, null)(UserProfileComponent);